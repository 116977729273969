<template>
  <div id="app" v-bind:class="[{ active: mobile === true }, {main: $route.path === '/'}]">

    <info v-if="$route.path !== '/'"/>

    <div class="center">
      <router-view/>
    </div>

    <template v-if="$route.path !== '/'">
      <top-menu/>

      <footer-project v-if="getShowFooter"/>

      <firstPopup v-show="getMenuCtrl.popupState === '00'"></firstPopup>

      <login v-show="getMenuCtrl.popupState === '1'"/>

      <question-popup v-show="getMenuCtrl.popupState === '2'"/>

      <registration v-show="getMenuCtrl.popupState === '3'"/>

      <personal-data v-show="getMenuCtrl.popupState === '4'"/>

      <!--    <transition name="fade">-->
      <!--      <personal-data-login v-show="getMenuCtrl.popupState === '5'"/>-->
      <!--    </transition>-->

      <user-info v-show="getMenuCtrl.popupState === '6'"/>

      <resources v-show="getMenuCtrl.popupState === '7'"/>
    </template>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'app',

  data: () => ({
    activeClass: "active",
    mobile: false,

    infoPopUp: false,
  }),

  created() {
    this.$root.globalUrl = this.axiosApiUrl();

    if (sessionStorage.getItem("key") === null) {
      this.setMenuData({popupState: '1'})
    }
  },

  watch: {
    'getMenuCtrl.popupState'(to, from) {
      if (to !== '0') {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      } else {
        document.getElementsByTagName("body")[0].style.overflow = "visible";
      }
    }
  },

  mounted() {

    this.$nextTick(() => {
      let ua = navigator.userAgent.toLowerCase();
      let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

      if (isAndroid || iOS === true) {
        this.mobile = true
      }
    })
  },

  computed: {
    ...mapGetters(['getMenuCtrl', 'getShowFooter']),
  },

  methods: {
    ...mapActions([
      'setMenuData'
    ]),

    axiosApiUrl() {
      let URLPATH = '';
      if (location.hostname.includes('localhost')) {
        URLPATH = 'https://hcp.nutricia.ua/'
        // URLPATH = 'https://hcp.inch.digital/'
      } else if (location.hostname.includes('hcpfront')) {
        console.log('test')
        URLPATH = 'https://hcp.inch.digital/'
      } else {
        URLPATH = 'https://' + location.hostname + '/';
      }

      return URLPATH;
    },
  }
}
</script>

<style lang="scss">

@import "./style/fonts";
@import "./style/vars";

.mx-table-date .today {
  color: #00ACE6 !important;
}

.mx-calendar-content .cell.active {
  color: #fff;
  background-color: #00ACE6 !important;

  &.today {
    color: #fff !important;
  }
}

.mx-calendar-header button {
  color: #00ACE6 !important;
  font-family: $fontB;
}

body {
  background: #EFF3F8;

  @media screen and (min-width: 426px) {
    background: #1E3B89;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;

}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}

#app {
  &.active {
    * {
      cursor: none !important;
      outline: none !important;
    }
  }

  text.highcharts-credits {
    display: none !important;
  }

  .btn,
  .close-btn,
  .close,
  .calc-btn,
  .to-menu-btn,
  a,
  button,
  label {
    cursor: pointer;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $fontR;
}

#app {
  max-width: 425px;
  width: 100%;

  margin: 0 auto;
  background: #EFF3F8;
  position: relative;

  padding-top: 60px;

  &.main {
    padding-top: 0;
  }

  @media screen and (min-width: 426px) {

    min-height: 100vh;
    &:before {
      content: 'Портал створений для перегляду на екрані смартфону, його можна встановити як додаток';
      color: white;
      font-family: $fontSB;
      font-size: 13px;

      position: fixed;

      max-width: 30%;
      width: 100%;
      top: 15px;

      left: 15px;


      margin: 0 auto;
      display: flex;
      align-items: center;
      @media screen and (max-width: 1200px) {
        max-width: 20%;
      }

      @media screen and (max-width: 700px) {
        display: none;
      }
    }
  }
}

#app > .center {

  @media screen and (min-width: 426px) {
    margin-top: 0;
    position: relative;
  }
}

a {
  text-decoration: none;
  color: #001434;
}
</style>
