<template>
  <div class="home">

    <div class="head-bg">
      <p class="h1-title">Ласкаво просимо<br>на Nutricia портал!</p>
    </div>

    <div class="send-question-block">
      <p class="h2-title"><span>Лінія підтримки</span><br>У Вас є питання? Напишіть нам!</p>
      <div class="open-question-btn" @click="questionDataPopup('1')">
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14.9123 1.97461H2.80212C1.81095 1.97461 1 2.83114 1 3.87802V10.9424V15.6166L3.62337 12.8458H14.9123C15.9035 12.8458 16.7145 11.9892 16.7145 10.9424V3.87802C16.7145 2.83114 15.9035 1.97461 14.9123 1.97461Z"
              stroke="#01A8E0" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
              stroke-linejoin="round"/>
          <path d="M4.5708 6.22729H13.2158" stroke="#01A8E0" stroke-width="2" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.5708 8.97925H10.3196" stroke="#01A8E0" stroke-width="2" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p>Написати</p>
      </div>
    </div>

    <div class="sampling-block">
      <div class="background"></div>
      <p class="h2-title"><span>Зразки продукції</span><br>Бажаєте оцінити дієвість? Замовляйте</p>
      <router-link to="/pediatrics/sampling" class="sampling-btn">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M18.6297 4.5197L18.6149 4.49411C18.5944 4.45157 18.5707 4.41083 18.5443 4.37212L15.7619 1.56172C15.5615 1.21523 15.1883 1 14.7881 1H5.21238C4.81148 1 4.43804 1.21574 4.23769 1.56317L1.40995 4.46888C1.38448 4.5131 1.36612 4.55935 1.35315 4.60634C1.31335 4.7138 1.29147 4.82993 1.29147 4.9511V17.6216C1.29147 18.3816 1.90979 19 2.6698 19H17.3301C18.0901 19 18.7085 18.3816 18.7085 17.6216V4.90466C18.7085 4.88767 18.708 4.87079 18.7071 4.85399C18.715 4.74122 18.6906 4.62493 18.6297 4.5197ZM10.6062 2H14.7608L16.5 4L10.6062 3.95642V2ZM5.23968 2H9.43387V3.95642L3.5 4L5.23968 2ZM17.5362 17.6217C17.5362 17.7353 17.4437 17.8277 17.3301 17.8277H2.6698C2.5562 17.8277 2.46378 17.7353 2.46378 17.6217V5.12872H17.5362V17.6217Z"
              fill="#1E3B89" stroke="#1E3B89" stroke-width="0.3"/>
          <path
              d="M13.8689 8.65257C13.573 8.35666 13.0931 8.35666 12.7971 8.65257L9.41316 12.0366L8.11193 10.7353C7.81602 10.4394 7.33611 10.4394 7.04015 10.7353C6.74419 11.0312 6.74419 11.5111 7.04015 11.8071L8.87735 13.6442C9.02533 13.7922 9.21932 13.8662 9.41321 13.8662C9.6071 13.8662 9.80115 13.7922 9.94908 13.6442L13.8689 9.72435C14.1649 9.42839 14.1649 8.94853 13.8689 8.65257Z"
              fill="#1E3B89"/>
        </svg>
        <p>Замовити</p>
      </router-link>
    </div>

    <div class="content-wrapper">

      <p class="h3-title">НОВИНИ</p>

      <div class="section section-calc">

        <img src="../assets/img/Nutrilon_MCM_C2_2024_news___001.jpg" alt="">

        <div class="more-info">
          <p class="h1-title">Infatrini з оновленою формулою пребіотиків</p>
          <p class="reg-text">
            Ефективна доза олігосахаридів GOS/FOS 9:1, розроблена для імітації різноманіття структур та функцій пребіотиків грудного молока, відтепер посилена олігосахаридом 2-FL, що є найпоширенішим пребіотиком в грудному молоці
          </p>
        </div>

        <div class="relocate">

          <a @click="goToNextStage('infatrini-liquid-HMO-Libra', 'calc2')" >ПЕРЕЙТИ</a>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>

      <div class="section section-calc">

        <img src="../assets/img/Nutrilon_MCM_C2_2024_news___004.jpg" alt="">

        <div class="more-info">
          <p class="h1-title">Підтримка грудного вигодовування передчасно народжених</p>
          <p class="reg-text">
            Мультикомпонентний збагачувач дозволяє зберегти грудне вигодовування передчасно народженої малечі завдяки підвищенню поживної цінності грудного молока до норм, що сприяють прискореному наздоганяючому росту та розвитку
          </p>
        </div>

        <div class="relocate">

          <a @click="goToNextStage('hmf-Renoir-2022', 'calc1')" >ПЕРЕЙТИ</a>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>

      <div class="section section-calc">

        <img src="../assets/img/Nutrilon_MCM_C2_2024__news_02_580x312_001.png" alt="">

        <div class="more-info">
          <p class="h1-title">Огляд продуктів при недостатності харчування</p>
          <p class="reg-text">
            Слід подолати недобір ваги або уникнути його? Пацієнт не може споживати адекватний об'єм або тверду їжу? – в оновленій інформаційній добірці стисла інформація про мальнутрицію та відповідне клінічне харчування
          </p>
        </div>

        <div class="relocate">

          <router-link to="/cards/fg" >ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>

      <div class="section section-calc">

        <img src="../assets/img/Nutrilon_MCM_C2_2024__news_02_580x312_001 (2).png" alt="">

        <div class="more-info">
          <p class="h1-title">Розширення лінійки Fortini для дітей від 1 року</p>
          <p class="reg-text">
            Нова суміш підійде дітям з поганим апетитом або підвищеними харчовими потребами, які не потребують стимулювання перистальтики. Суха форма зручна для споживання протягом тривалого часу або у значному об'ємі
          </p>
        </div>

        <div class="relocate">

          <a @click="goToNextStage('fortini-15-powder', 'calc2')">ПЕРЕЙТИ</a>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>

      <div class="section section-calc">

        <img src="../assets/img/Nutrilon_MCM_C2_2024__news_02_580x312_002.png" alt="">

        <div class="more-info">
          <p class="h1-title">5 років допомоги онлайн</p>
          <p class="reg-text">
            Портал Nutricia запрацював у вересні 2019 року й швидко поповнювався матеріалами та сервісами, щоб 24/7 бути корисним особисто вам. Будь ласка, напишіть нам: в яких ситуаціях портал ставав особливо в нагоді, та які ще послуги ви хотіли б тут отримувати
          </p>
        </div>

        <div class="relocate">

          <a @click="questionDataPopup()" >НАПИСАТИ</a>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>

      <div class="section section-calc">

        <img src="../assets/img/Nutrilon_MCM_C2_2024__news_01_580x312_001.png" alt="">

        <div class="more-info">
          <p class="h1-title">Оновлений графік до кінця 2024 року</p>
          <p class="reg-text">
            До усіх заходів можна долучитися онлайн, але збільшується кількість конференцій та конгресів, які можна відвідати аудиторно. Позначте цікаві вам заходи та періодично передивляйтеся, чи відкрито на них реєстрацію
          </p>
        </div>

        <div class="relocate">

          <router-link to="/conferences" >ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>

      <div class="section section-calc">

        <img src="../assets/img/Nutrilon_MCM_C2_2023_580x312.png" alt="">

        <div class="more-info">
          <p class="h1-title">Заряд енергії та поживних речовин для дітей від 1 року</p>
          <p class="reg-text">
            Відтепер в Україні! Напій, що сприяє покращенню апетиту «малоїжок» (надаючи сили перетравити адекватний
            обсяг звичайної їжі), задовольняє підвищені потреби фізично активних дітей (спортсменів, танцівників), а
            також дітей, які часто хворіють чи мають хронічні захворювання
          </p>
        </div>

        <div class="relocate">

          <a @click="goToNextStage('fortini-mf6-15-liquid', 'calc2')">ПЕРЕЙТИ</a>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>

      <div class="section section-calc">

        <img src="../assets/img/Nutrilon_MCM_C1_2022_Healthy_Kids_580x312_002.png" alt="">

        <div class="more-info">
          <p class="h1-title">Для дітей з фармакорезистентною епілепсією</p>
          <p class="reg-text">
            Нарешті в Україні унікальні суміші для терапевтичної кетогенної дієти при фармакорезистентній епілепсії. Для
            правильного розрахунку харчування зверніться до нас на Лінію підтримки
          </p>
        </div>

        <div class="relocate">

          <a @click="goToNextStage('ketocal-3-1', 'calc1')">ПЕРЕЙТИ</a>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>


      <div class="section section-calc">

        <img src="../assets/img/new-banner-may.png" alt="">

        <div class="more-info">
          <p class="h1-title">Nutrilon Profutura розкриває секрети грудного молока</p>
          <p class="reg-text">
            Найінноваційніша базова суміш відтепер в Україні! Завдяки наближеності амінокислотного, ліпідного та
            пребіотичного профілю суміші до грудного молока, вона особливо підійде малечі на змішаному вигодовуванні, а
            також передчасно народженим, що досягли 10 перцентилю за вагою

          </p>
        </div>

        <div class="relocate">

          <router-link to="/calculator">ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>

<!--      <div class="section section-calc">-->

<!--        <img src="../assets/img/new-banner-conf.png" alt="">-->

<!--        <div class="more-info">-->
<!--          <p class="h1-title">Графік конференцій і семінарів з дитячої та клінічної нутриціології 2023</p>-->
<!--          <p class="reg-text">-->
<!--            З міркувань безпеки, найближчим часом заходи відбуватимуться онлайн. Зазвичай організатори надають-->
<!--            можливість перегляду відеозапису впродовж декількох днів. Позначте цікаві вам заходи та періодично-->
<!--            передивляйтеся, чи не відкрито на них реєстрацію-->

<!--          </p>-->
<!--        </div>-->

<!--        <div class="relocate">-->

<!--          <router-link to="/conferences">ПЕРЕЙТИ</router-link>-->
<!--          <div class="arrow">-->
<!--            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
<!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
<!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </svg>-->

<!--          </div>-->

<!--        </div>-->
<!--      </div>-->

      <div class="section section-calc">

        <img src="../assets/img/allergy.jpg" alt="">

        <div class="more-info">
          <p class="h1-title">Дієтотерапія при проявах харчової алергії</p>
          <p class="reg-text">
            Дієтотерапія вважається основним шляхом вирішення проблеми харчової алергії. Професійний
            портфель глибокогідролізованих та амінокислотних сумішей Nutricia є найширшим в Україні. Дізнайтесь, у чому
            відмінності між сумішами та який алгоритм їх підбору
          </p>
        </div>

        <div class="relocate">

          <router-link to="/cards/allergy">ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>

      <div class="section section-calc">

        <img src="../assets/img/healthy.jpg" alt="">

        <div class="more-info">
          <p class="h1-title">Nutrilon Для чутливих малюків</p>
          <p class="reg-text">
            Нова назва гіпоалергенної формули покликана мінімізувати помилки у підборі суміші здоровій малечі без
            проявів, але з ризиком розвитку харчової алергії до білків коров'ячого молока. Формула лишається незмінною.
            Знайдіть її місце у новому алгоритмі підбору харчування здоровим дітям раннього віку
          </p>
        </div>

        <div class="relocate">

          <router-link to="/algorithms/healthy">ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>

<!--      <div class="section section-calc">-->

<!--        <img src="../assets/img/premium.png" alt="">-->

<!--        <div class="more-info">-->
<!--          <p class="h1-title">Оновлена формула базових сумішей Nutrilon Premium+</p>-->
<!--          <p class="reg-text">-->
<!--            Збережено усі ключові переваги Nutrilon (ефективна доза пребіотиків GOS/FOS 9:1, постбіотики й багатий-->
<!--            вітамінно-мінеральний комплекс). Стає ще більше довголанцюгових поліненасичених жирних кислот й вітаміну-->
<!--            D<sub>3</sub>-->
<!--          </p>-->
<!--        </div>-->

<!--        <div class="relocate">-->

<!--          <a @click="goToNextStage('base-1-powder-POF', 'calc1')">ПЕРЕЙТИ</a>-->
<!--          <div class="arrow">-->
<!--            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
<!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
<!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </svg>-->

<!--          </div>-->

<!--        </div>-->
<!--      </div>-->

      <!--      <div class="section section-calc">-->

      <!--        <img src="../assets/img/conference.jpg" alt="">-->

      <!--        <div class="more-info">-->
      <!--          <p class="h1-title">Графік конференцій і семінарів з дитячої та клінічної нутриціології 2022</p>-->
      <!--          <p class="reg-text">-->
      <!--            Організатори заходів планують поступово повертатися до аудиторних засідань, але супруводжуватимуть їх-->
      <!--            онлайн-трансляцією для зручності кожного слухача.-->
      <!--            Відмічайте найбільш цікаві особисто вам, щоб швидше знаходити, реєструватися й підраховувати кількість-->
      <!--            отриманих атестаційних балів-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <div class="relocate">-->

      <!--          <router-link to="/conferences">ПЕРЕЙТИ</router-link>-->
      <!--          <div class="arrow">-->
      <!--            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--            </svg>-->

      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->

      <div class="section section-calc">

        <img src="../assets/img/new-alg.png" alt="">

        <div class="more-info">
          <p class="h1-title">Новий алгоритм діагностики й корекції недостатності харчування</p>
          <p class="reg-text">
            Потрібно оцінити недостатність харчування пацієнта й визначити шляхи корекції, але занурюватись у таблиці та
            розрахунки не має часу? Скористайтесь нашим інтерактивним алгоритмом, який значно спрощує діагностику й
            підбір харчування
          </p>
        </div>

        <div class="relocate">

          <router-link to="/algorithms/fg">ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>

      <!--      <div class="section section-calc">-->

      <!--        <img src="../assets/img/LegalClinic_Answer_October_2021/Nutrilon_MCM_C2_2021_Clinic-02_580x312_001.jpg" alt="">-->

      <!--        <div class="more-info">-->
      <!--          <p class="h1-title">Відповіді на запитання</p>-->
      <!--          <p class="reg-text">-->
      <!--            Ознайомтеся з відповідями на запитання, отримані експертом нашої юридичної клініки. Навіть якщо подібні-->
      <!--            питання у вашій практиці не виникали, досвід може стати в нагоді-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <div class="relocate">-->

      <!--          <router-link to="/cards/legal_clinic_2021">ПЕРЕЙТИ</router-link>-->
      <!--          <div class="arrow">-->
      <!--            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--            </svg>-->

      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="section section-calc">-->
      <!--        <img src="../assets/img/lawyer.jpg" alt="">-->

      <!--        <div class="more-info">-->
      <!--          <p class="h1-title">Поставте запитання медичному адвокату</p>-->
      <!--          <p class="reg-text">-->
      <!--            Маєте запитання до експерта, що спеціалізується на медичному й трудовому праві? До 15 жовтня у вас є-->
      <!--            можливість анонімно поставити його Сергію Кубанському, Голові Ради Київської міської профспілки працівників-->
      <!--            охорони здоров'я-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <div class="relocate">-->

      <!--          <a style="opacity: .3; cursor: default">НАПИСАТИ</a>-->
      <!--          <div style="opacity: .3" class="arrow">-->
      <!--            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--            </svg>-->

      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="section section-calc">-->

      <!--        <img src="../assets/img/nutrilon-125.png" alt="">-->

      <!--        <div class="more-info">-->
      <!--          <p class="h1-title">125 років Nutricia</p>-->
      <!--          <p class="reg-text">-->
      <!--            Історія Nutricia розпочалась 20 липня 1896 року з патентування перших адаптованих дитячих сумішей. Усі-->
      <!--            дослідження, інновації та натхнення присвячуємо турботі про здоров'я кожного-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <div class="relocate">-->

      <!--          <router-link to="/cards/n125">ПЕРЕЙТИ</router-link>-->
      <!--          <div class="arrow">-->
      <!--            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--            </svg>-->

      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="section section-calc">-->

      <!--        <img src="../assets/img/cathedra-img-2.png" alt="">-->

      <!--        <div class="more-info">-->
      <!--          <p class="h1-title">Відповіді на запитання</p>-->
      <!--          <p class="reg-text">-->
      <!--            Ознайомтесь з відповідями на запитання, які надійшли до віртуальної кафедри порталу Nutricia. Двері нашої-->
      <!--            кафедри не зачиняються, рубрика стає постійною – звертайтесь за порадами щодо дієтотерапії у складних-->
      <!--            клінічних випадках-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <div class="relocate">-->

      <!--          <router-link to="/pediatrics/cathedra">ПЕРЕЙТИ</router-link>-->
      <!--          <div class="arrow">-->
      <!--            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--            </svg>-->

      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->

      <!-- <div class="section section-calc">

        <img src="../assets/img/cathedra-img.png" alt="">

        <div class="more-info">
          <p class="h1-title">Відкрита кафедра</p>
          <p class="reg-text">
            Маєте нагальні запитання до науковців щодо дитячого харчування? У вас є можливість поставити їх до 15 червня провідним вченим-педіатрам. Ми оперативно надамо вам відповідь, а до Дня медичного працівника (20 червня цьогоріч) опублікуємо найцікавіше на нашому порталі
          </p>
        </div>

        <div class="relocate">

          <router-link to="/pediatrics/cathedra">ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div> -->

      <!-- <div class="section section-calc">

        <img src="../assets/img/faltering-growth-news.jpg" alt="">

        <div class="more-info">
          <p class="h1-title">Комплексна корекція недостатності харчування</p>
          <p class="reg-text">
            Чим нагодувати «малоїжку» або дитину, що потребує високопоживного харчування у лікарні? Пропонуємо комплексний підхід до посиленої нутритивної підтримки від народження до зрілого віку
          </p>
        </div>

        <div class="relocate">

          <router-link to="/cards/fg">ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div> -->

      <!-- <div class="section section-calc">

        <img src="../assets/img/comfort-card-news.png" alt="">

        <div class="more-info">
          <p class="h1-title">Nutrilon Комфорт святкує ювілей!</p>
          <p class="reg-text">
            У 2000 році Nutricia створила першу в світі суміш для комфортного травлення і продовжує її вдосконалювати. Чи всі властивості формули вам відомі?
          </p>
        </div>

        <div class="relocate">

          <router-link to="/cards/comfort">ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div> -->

      <!--      <div class="section section-calc">-->

      <!--        <img src="../assets/img/legal-clinic-new.png" alt="">-->

      <!--        <div class="more-info">-->
      <!--          <p class="h1-title">Відповіді на запитання</p>-->
      <!--          <p class="reg-text">-->
      <!--            Ознайомтеся з відповідями на запитання, отримані нашою юридичною клінікою. Навіть якщо подібні запитання у вашій практиці не виникали, відповіді на них можуть стати у нагоді-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <div class="relocate">-->

      <!--          <router-link to="/legal_clinic_2020">ПЕРЕЙТИ</router-link>-->
      <!--          <div class="arrow">-->
      <!--            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--            </svg>-->

      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->


      <!--      <div class="section section-calc">-->

      <!--        <img src="../assets/img/lower-clinic.png" alt="">-->

      <!--        <div class="more-info">-->
      <!--          <p class="h1-title">Юридична клініка</p>-->
      <!--          <p class="reg-text">-->
      <!--            Маєте запитання до адвоката, що спеціалізується на медичному праві? До 20 жовтня у вас є можливість анонімно поставити його Олені Бабич, експерту з 17-річним досвідом. Очікуйте публікацію відповідей на порталі-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <div class="relocate gray">-->

      <!--          <a @click="finish()">ЗАВЕРШЕНА</a>-->
      <!--          <div class="arrow">-->
      <!--            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path d="M1 1.00001L5.53548 5.5127" stroke="#D7E2EC" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--              <path d="M5.53548 5.51273L1 10.022" stroke="#D7E2EC" stroke-width="2" stroke-miterlimit="10"-->
      <!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--            </svg>-->

      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->

      <!-- <div class="section section-calc">

        <img src="../assets/img/faltering-growth.jpg" alt="">

        <div class="more-info">
          <p class="h1-title">Добові норми калорій і білка</p>
          <p class="reg-text">
            Ми розробили калькулятор сумішей для дітей з відставанням у розвитку, мальнутрицією й іншими проблемами, що вимагають багато енергії і білка. Звільніть себе від арифметики – вкажіть стан пацієнта й отримайте готовий розрахунок
          </p>
        </div>

        <div class="relocate">

          <router-link to="/calculator">ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div> -->

      <!-- <div class="section section-calc">

        <img src="../assets/img/library-news.jpg" alt="">

        <div class="more-info">
          <p class="h1-title">Запрошуємо до Бібліотеки!</p>
          <p class="reg-text">
            У новому розділі порталу ми зібрали наукові статті, методичні рекомендації й інші матеріали з дієтотерапії дітей раннього віку. На вас вже чекає свіжа стаття професора С.Л. Няньковського про постбіотики у дитячому харчуванні
          </p>
        </div>

        <div class="relocate">

          <router-link to="/library">ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div> -->

      <!-- <div class="section section-calc">

        <img src="../assets/img/home.png" alt="">

        <div class="more-info">
          <p class="h1-title">Nutrilon з постбіотиками</p>
          <p class="reg-text">
            Нові формули на крок ближчі до грудного молока. Запатентована технологія збагачує їх постбіотиками –
            метаболітами корисної мікрофлори, що природньо містяться у грудному молоці, легко засвоюються, мають
            позитивні локальні та системні ефекти
          </p>
        </div>

        <div class="relocate">

          <router-link to="/calculator">ПЕРЕЙТИ</router-link>
          <div class="arrow">
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.00001L5.53548 5.5127" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.53548 5.51273L1 10.022" stroke="#00ACE6" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>

        </div>
      </div>   -->

    </div>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'home',

  data: () => ({}),

  created() {
    document.body.style.background = '#1E3B89';
  },

  methods: {
    ...mapActions([
      'setMenuData'
    ]),
    questionDataPopup() {
      this.setMenuData({popupState: '2'})
    },

    goToNextStage(prodNumber, to) {
      sessionStorage.setItem("production", prodNumber);

      if (to === 'calc1') {
        this.$router.push({name: 'prod', params: {id: prodNumber}})
      }
      if (to === 'calc2') {
        this.$router.push({name: 'prod-energy', params: {id: prodNumber}})
      }
    },

    finish() {
      return false;
    },

  }
}
</script>

<style lang="scss">
@import "../style/vars";

.home {
  position: relative;

  .components_send-question-block {
    display: none !important;
  }

  .head-bg {
    background: url("../assets/img/head-bg.jpg") no-repeat top center;
    background-size: cover;
    width: 100%;
    height: 485px;

    .h1-title {
      padding-top: 25px;
      text-align: center;
      color: #1E3B89;
      font-family: $fontR;
      font-size: 24px;
    }
  }

  .send-question-block {
    background: url("../assets/img/question-bg.jpg") no-repeat top center;
    background-size: cover;
    width: 100%;
    height: 229px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .h2-title {
      font-family: $fontSB;
      font-size: 16px;
      text-align: center;
      color: white;

      span {
        font-family: $fontB;
        font-size: 28px;
      }

    }

    .open-question-btn {
      margin-top: 25px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 4px;
      width: 148px;
      height: 41px;
      transition: all 0.2s ease;

      svg {
        path {
          transition: all 0.2s ease;

        }
      }

      p {
        transition: all 0.2s ease;
        margin-left: 15px;
        text-transform: uppercase;
        font-size: 15px;
        font-family: $fontB;
        color: #00ACE6;
      }

      &:active {
        background-color: #00ACE6;

        p {
          color: white;
        }

        svg {
          path {
            stroke: white;
          }
        }
      }
    }
  }

  .sampling-block {
    position: relative;
    background: url("../assets/images/sampling-bg.png") no-repeat center;
    background-size: cover;
    width: 100%;
    height: 229px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(152.01deg, #4179CD 21.85%, #08449E 93.49%);
      opacity: 0.65;
    }

    .h2-title {
      position: relative;
      font-family: $fontSB;
      font-size: 16px;
      text-align: center;
      color: white;

      span {
        font-family: $fontB;
        font-size: 28px;
      }
    }

    .sampling-btn {
      position: relative;
      margin-top: 25px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 4px;
      width: 148px;
      height: 41px;
      transition: all 0.2s ease;

      svg {
        path {
          transition: all 0.2s ease;
        }
      }

      p {
        transition: all 0.2s ease;
        margin-left: 15px;
        text-transform: uppercase;
        font-size: 15px;
        font-family: $fontB;
        color: #1E3B89;
      }

      &:active {
        background-color: #1E3B89;

        p {
          color: white;
        }

        svg {
          path {
            stroke: white;
          }
        }
      }
    }
  }

  .h3-title {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 25px 0;
    color: #9AB9D8;
    font-family: $fontB;
    font-size: 15px;
  }

  .content-wrapper {
    padding: 0 15px;

    .section {
      max-width: 395px;
      width: 100%;
      margin: 0 auto 15px auto;

      background-color: white;
      border-radius: 4px;
      overflow: hidden;


      img {
        width: 100%;
        height: auto;
      }

      .more-info {
        padding: 0 15px;
        margin-top: 20px;

        .h1-title {
          font-family: $fontR;
          font-size: 20px;
          margin-bottom: 15px;
          color: #1E3B89;
        }

        .reg-text {
          font-family: $fontL;
          font-size: 14px;
          color: #1E3B89;
          margin-bottom: 30px;
        }
      }

      .relocate {
        border-top: 1px solid #DAE3ED;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 0;

        a {
          color: #00ACE6;
          letter-spacing: 2px;
          font-family: $fontB;
          margin-right: 7px;
          font-size: 15px;
        }
      }

      .gray {
        a {
          color: #D7E2EC;
        }
      }
    }
  }

  .section-nav {
    display: flex;
    flex-flow: row wrap;

    a {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:nth-child(1) {
        border-top: 1px solid #D7E2EC;
        border-right: 1px solid #D7E2EC;
        border-bottom: 1px solid #D7E2EC;
      }

      &:nth-child(2) {
        border-top: 1px solid #D7E2EC;
        border-bottom: 1px solid #D7E2EC;
      }

      &:nth-child(3) {
        border-right: 1px solid #D7E2EC;
      }

      &:active {
        svg {
          path {
            stroke-width: 2;
          }
        }
      }

      svg {
        path {
          transition: all 0.2s ease;
        }
      }
    }

    .section-nav-item {
      height: 185px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .icon {
        margin-top: 40px;
        margin-bottom: 25px;
        height: 64px;

      }

      .text {
        text-align: center;
        color: #1E3B89;
        font-size: 14px;
        font-family: $fontB;
        text-transform: uppercase;
      }


    }

  }

  .footer {
    background-color: #E0E9F1;
    width: 100%;
    padding: 40px 30px;

    p {
      font-size: 11px;
      margin-bottom: 25px;
      color: #1E3B89;
      font-family: $fontR;

      &.footer-h1 {
        font-family: $fontSB;
      }
    }
  }

}
</style>
